

const venueTypesArray = [
    "Arts & Entertainment",
    "Arts & Entertainment:Aquarium",
    "Arts & Entertainment:Bowling Alley",
    "Arts & Entertainment:Casino",
    "Arts & Entertainment:Movie Theater",
    "Arts & Entertainment:Movie Theater:AMC Theater",
    "Arts & Entertainment:Movie Theater:Arts Picturehouse",
    "Arts & Entertainment:Movie Theater:Curzon Cinemas",
    "Arts & Entertainment:Movie Theater:Everyman Cinemas",
    "Arts & Entertainment:Movie Theater:Lighthouse Cinema",
    "Arts & Entertainment:Movie Theater:Odeon Cinemas",
    "Arts & Entertainment:Movie Theater:Pacific Cinemas",
    "Arts & Entertainment:Movie Theater:Picturehouse",
    "Arts & Entertainment:Movie Theater:Reel Cinemas",
    "Arts & Entertainment:Movie Theater:Regal Cinemas",
    "Arts & Entertainment:Movie Theater:Regent Street Cinema",
    "Arts & Entertainment:Movie Theater:The Light Cinema",
    "Arts & Entertainment:Movie Theater:Tyneside Cinema",
    "Arts & Entertainment:Movie Theater:Vue Cinemas",
    "Arts & Entertainment:Museum",
    "Arts & Entertainment:Performing Arts Venue",
    "Arts & Entertainment:Stadium",
    "Arts & Entertainment:Stadium:Baseball Stadium",
    "Arts & Entertainment:Stadium:Football Stadium",
    "Arts & Entertainment:Stadium:Soccer Stadium",
    "Arts & Entertainment:Theme Park",
    "Arts & Entertainment:Water Park",
    "Arts & Entertainment:Zoo",
    "College & University",
    "College & University:College Stadium",
    "Commercial WiFi",
    "Event:Convention",
    "Food",
    "Food:Bakery",
    "Food:Cafeteria",
    "Food:Café",
    "Food:Coffee Shop",
    "Food:Dessert Shop:Frozen Yogurt Shop",
    "Food:Dessert Shop:Ice Cream Shop",
    "Food:Dessert Shop:Pastry Shop",
    "Food:Fast Food Restaurant",
    "Food:Restaurant",
    "Food:Restaurant:Dessert Shop",
    "Food:Restaurant:Italian",
    "Food:Restaurant:Pizza",
    "Leisure",
    "Nightlife Spot:Bar",
    "Nightlife Spot:Bar:Sports Bar",
    "Nightlife Spot:Brewery",
    "Nightlife Spot:Lounge",
    "Other Workplace:Construction:Building Construction General Contractors And Operative Builders",
    "Other Workplace:Construction:Construction Special Trade Contractors",
    "Other Workplace:Construction:Heavy Construction Other Than Building Construction Contractors",
    "Other Workplace:Manufacturing:Apparel And Other Finished Products Made From Fabrics And Similar Materials",
    "Other Workplace:Manufacturing:Chemicals And Allied Products",
    "Other Workplace:Manufacturing:Electronic And Other Electrical Equipment And Components, Except Computer Equipment",
    "Other Workplace:Manufacturing:Fabricated Metal Products, Except Machinery And Transportation Equipment",
    "Other Workplace:Manufacturing:Food And Kindred Products",
    "Other Workplace:Manufacturing:Furniture And Fixtures",
    "Other Workplace:Manufacturing:Industrial And Commercial Machinery And Computer Equipment",
    "Other Workplace:Manufacturing:Leather And Leather Products",
    "Other Workplace:Manufacturing:Lumber And Wood Products, Except Furniture",
    "Other Workplace:Manufacturing:Measuring, Analyzing, And Controlling Instruments; Photographic, Medical And Optical Goods; Watches And Clocks",
    "Other Workplace:Manufacturing:Miscellaneous Manufacturing Industries",
    "Other Workplace:Manufacturing:Paper And Allied Products",
    "Other Workplace:Manufacturing:Petroleum Refining And Related Industries",
    "Other Workplace:Manufacturing:Primary Metal Industries",
    "Other Workplace:Manufacturing:Printing, Publishing, And Allied Industries",
    "Other Workplace:Manufacturing:Rubber And Miscellaneous Plastics Products",
    "Other Workplace:Manufacturing:Stone, Clay, Glass, And Concrete Products",
    "Other Workplace:Manufacturing:Textile Mill Products",
    "Other Workplace:Manufacturing:Tobacco Products",
    "Other Workplace:Manufacturing:Transportation Equipment",
    "Other Workplace:Mining:Coal Mining",
    "Other Workplace:Mining:Metal Mining",
    "Other Workplace:Mining:Mining And Quarrying Of Nonmetallic Minerals, Except Fuels",
    "Other Workplace:Mining:Oil And Gas Extraction",
    "Other Workplace:Public Infrastructure:Communications",
    "Other Workplace:Public Infrastructure:Electric, Gas, And Sanitary Services",
    "Other Workplace:Public Infrastructure:Motor Freight Transportation And Warehousing",
    "Other Workplace:Public Infrastructure:Pipelines, Except Natural Gas",
    "Other Workplace:Public Infrastructure:Railroad Transportation",
    "Other Workplace:Public Infrastructure:Transportation By Air",
    "Other Workplace:Public Infrastructure:Transportation Services",
    "Other Workplace:Public Infrastructure:Water Transportation",
    "Outdoors & Recreation",
    "Outdoors & Recreation:Athletics & Sports",
    "Outdoors & Recreation:Athletics & Sports:Golf Course",
    "Outdoors & Recreation:Athletics & Sports:Gym / Fitness Center",
    "Outdoors & Recreation:Cemetery",
    "Outdoors & Recreation:Harbor / Marina",
    "Outdoors & Recreation:Indoor Play Area",
    "Outdoors & Recreation:Pool",
    "Professional & Other Places",
    "Professional & Other Places:Funeral Home",
    "Professional & Other Places:Government Building",
    "Professional & Other Places:Library",
    "Professional & Other Places:Medical Center",
    "Professional & Other Places:Medical Center:Dentist's Office",
    "Professional & Other Places:Medical Center:Hospital",
    "Professional & Other Places:Medical Center:Rehab Center",
    "Professional & Other Places:Military Base",
    "Professional & Other Places:Office",
    "Professional & Other Places:School",
    "Professional & Other Places:Spiritual Center",
    "Residence",
    "Residence:Residential Building (Apartment / Condo)",
    "Shop & Service",
    "Shop & Service:Arts & Crafts Store",
    "Shop & Service:Auto Dealership",
    "Shop & Service:Automotive Shop",
    "Shop & Service:Baby Store",
    "Shop & Service:Bank",
    "Shop & Service:Big Box Store",
    "Shop & Service:Boat Dealership",
    "Shop & Service:Bookstore",
    "Shop & Service:Butcher",
    "Shop & Service:Candy Store",
    "Shop & Service:Car Wash",
    "Shop & Service:Clothing Store",
    "Shop & Service:Clothing Store:Kids Store",
    "Shop & Service:Clothing Store:Men's Store",
    "Shop & Service:Clothing Store:Music Store",
    "Shop & Service:Clothing Store:Shoe Store",
    "Shop & Service:Clothing Store:Women's Store",
    "Shop & Service:Construction & Landscaping",
    "Shop & Service:Convenience Store",
    "Shop & Service:Credit Union",
    "Shop & Service:Department Store",
    "Shop & Service:Discount Store",
    "Shop & Service:Electronics Store",
    "Shop & Service:Farmers Market",
    "Shop & Service:Financial or Legal Service",
    "Shop & Service:Fish Market",
    "Shop & Service:Flower Shop",
    "Shop & Service:Food & Drink Shop",
    "Shop & Service:Food & Drink Shop:Supermarket",
    "Shop & Service:Furniture / Home Store",
    "Shop & Service:Garden Center",
    "Shop & Service:Gas Station",
    "Shop & Service:Gift Shop",
    "Shop & Service:Hardware Store",
    "Shop & Service:Health & Beauty Service",
    "Shop & Service:Insurance",
    "Shop & Service:Jewelry Store",
    "Shop & Service:Laundromat",
    "Shop & Service:Liquor Store",
    "Shop & Service:Mobile Phone Shop",
    "Shop & Service:Motorcycle Dealership",
    "Shop & Service:Music Store",
    "Shop & Service:Newsstand",
    "Shop & Service:Optical Shop",
    "Shop & Service:Paper / Office Supplies Store",
    "Shop & Service:Pawn Shop",
    "Shop & Service:Pet Store",
    "Shop & Service:Pharmacy",
    "Shop & Service:Photography Studio",
    "Shop & Service:RV Dealership",
    "Shop & Service:Real Estate Office",
    "Shop & Service:Salon / Barbershop",
    "Shop & Service:Shipping Store",
    "Shop & Service:Shopping Mall",
    "Shop & Service:Smoke Shop",
    "Shop & Service:Sporting Goods Shop",
    "Shop & Service:Toy / Game Store",
    "Shop & Service:Travel Agency",
    "Travel & Transport",
    "Travel & Transport:Airport",
    "Travel & Transport:Airport:Airport Lounge",
    "Travel & Transport:Boat or Ferry",
    "Travel & Transport:Bus Station",
    "Travel & Transport:Cruise",
    "Travel & Transport:Hotel",
    "Travel & Transport:Hotel:Bed & Breakfast",
    "Travel & Transport:Hotel:Resort",
    "Travel & Transport:Hotel:Vacation Rental",
    "Travel & Transport:Rental Car Location",
    "Travel & Transport:Taxi",
    "Travel & Transport:Train Station",
    "Wholesale Trade",
    "Wholesale Trade:Auto Dealership",
    "Wholesale Trade:Automotive Shop",
    "Wholesale Trade:Bookstore",
    "Wholesale Trade:Butcher",
    "Wholesale Trade:Candy Store",
    "Wholesale Trade:Clothing Store:Men's Store",
    "Wholesale Trade:Clothing Store:Shoe Store",
    "Wholesale Trade:Clothing Store:Women's Store",
    "Wholesale Trade:Construction & Landscaping",
    "Wholesale Trade:Electronics Store",
    "Wholesale Trade:Farmers Market",
    "Wholesale Trade:Fish Market",
    "Wholesale Trade:Food & Drink Shop",
    "Wholesale Trade:Food & Drink Shop:Supermarket",
    "Wholesale Trade:Furniture / Home Store",
    "Wholesale Trade:Garden Center",
    "Wholesale Trade:Gas Station",
    "Wholesale Trade:Hardware Store",
    "Wholesale Trade:Jewelry Store",
    "Wholesale Trade:Laundromat",
    "Wholesale Trade:Liquor Store",
    "Wholesale Trade:Medical Supply Store",
    "Wholesale Trade:Paper / Office Supplies Store",
    "Wholesale Trade:Pharmacy",
    "Wholesale Trade:Photography Lab",
    "Wholesale Trade:Real Estate Office",
    "Wholesale Trade:Sporting Goods Shop",
    "Wholesale Trade:Toy / Game Store"
];

export default venueTypesArray;

