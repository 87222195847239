import React from "react";

class SearchField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: ""
    };
    this.onChange = this.onChange.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.triggerSearch = this.triggerSearch.bind(this);
  }

  onChange(event) {
    this.setState({
      value: event.target.value
    });
  }

  onKeyDown(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.triggerSearch();
    }
  }

  triggerSearch() {
    const { value } = this.state;
    this.props.onChange(value);
  }

  render() {
    return (
      <div>
        <div>
          <input
            type="text"
            placeholder="Search..."
            size="64"
            value={this.state.value}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
          />
        </div>
        <p>
          Search term can be partial, exact, hex, ascii or fuzzy matched.
          Queries examine data in the venueToken and bssTokenToVenueToken tables
          and consider venue names, venue tokens, venue types, tags and bss
          tokens.
        </p>
      </div>
    );
  }
}

export default SearchField;
