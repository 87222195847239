import React from "react";

import SearchField from "./SearchField";
import VenueSearchResults from "./VenueSearchResults";

class Search extends React.Component {
  render() {
    return (
      <div>
        <form id="search">
          <SearchField onChange={this.props.startSearch} />
          <div className="none">{this.props.status}</div>
        </form>
        {this.props.fetching ? (
          // Display the animated 'in progress' indicator.
          <div id="bouncing-poi">
            <img
              alt="bouncing poi"
              className="bounce animate"
              id="bouncing-poi"
              src="/poi.png"
            />
          </div>
        ) : (
          // Display the list of matching venueTokens and a sumamry of their associated data.
          // This is a read-only table.
          <VenueSearchResults
            data={this.props.data}
            description={this.props.dataDescription}
            onSelectRow={this.props.onSelectRow}
            onDeleteRow={this.props.onDeleteRow}
            onCreate={this.props.onCreate}
          />
        )}
      </div>
    );
  }
}

export default Search;
