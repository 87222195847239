export const putVenueToken = `mutation PutVenueToken(
  $venueToken: String!
  $venueName: String
  $venueType: String
  $tags: [String]
  $bssTokens: [bssTokenInput]
) {
  putVenueToken(
    venueToken: $venueToken
    venueName: $venueName
    venueType: $venueType
    tags: $tags
    bssTokens: $bssTokens
  ) {
    venueToken
    venueName
    venueType
    tags
    bssTokens {
      bssToken
      affinity
      matchType
    }
  }
}
`;
export const deleteBssTokens = `mutation DeleteBssTokens(
  $bssTokens: [bssTokenInput]
) {
  deleteBssTokens(
    bssTokens: $bssTokens
  ) 
}
`;
export const deleteVenueToken = `mutation DeleteVenueToken(
  $venueToken: String!
) {
  deleteVenueToken(
    venueToken: $venueToken
  ) 
}
`;
