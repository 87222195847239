import React from "react";

import DropdownTreeSelect from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";
import venueTypesArray from "./venueTypes";

class VenueType extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(currentNode, selectedNodes) {
    this.props.onChange(currentNode.value);
    document.getElementsByClassName("dropdown-trigger arrow top")[0].click();
  }

  getTree(selectedType) {
    var treeSelectData = [];
    var venueTypesDict = {};

    venueTypesArray.forEach(type => {
      var [l0, l1, l2] = type.split(":");

      if (!(l0 in venueTypesDict)) {
        venueTypesDict[l0] = {};
      }
      if (l1) {
        if (!(l1 in venueTypesDict[l0])) {
          venueTypesDict[l0][l1] = {};
        }
      }
      if (l2) {
        if (!(l2 in venueTypesDict[l0][l1])) {
          venueTypesDict[l0][l1][l2] = {};
        }
      }
    });

    Object.keys(venueTypesDict).forEach(function(l0key) {
      var obj = {
        label: l0key,
        value: l0key,
        children: []
      };
      if (selectedType === l0key) {
        obj.checked = true;
      }
      treeSelectData.push(obj);

      Object.keys(venueTypesDict[l0key]).forEach(function(l1key) {
        var l0children = treeSelectData[treeSelectData.length - 1].children;
        var type = l0key + ":" + l1key;
        var obj = {
          label: type,
          value: type,
          children: []
        };
        if (selectedType === type) {
          obj.checked = true;
        }
        l0children.push(obj);

        Object.keys(venueTypesDict[l0key][l1key]).forEach(function(l2key) {
          var l1children = l0children[l0children.length - 1].children;
          var type = l0key + ":" + l1key + ":" + l2key;
          var obj = {
            label: type,
            value: type
          };
          if (selectedType === type) {
            obj.checked = true;
          }
          l1children.push(obj);
        });
        if (l0children[l0children.length - 1].children.length === 0) {
          delete l0children[l0children.length - 1].children;
        }
      });
      if (treeSelectData[treeSelectData.length - 1].children.length === 0) {
        delete treeSelectData[treeSelectData.length - 1].children;
      }
    });
    return treeSelectData;
  }

  render() {
    return (
      <DropdownTreeSelect
        placeholderText="..."
        data={this.getTree(this.props.type)}
        onChange={this.onChange}
        clearSearchOnChange={true}
        showDropdown={false}
      />
    );
  }
}

export default VenueType;
