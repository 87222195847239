import React from "react";
import { WithContext as ReactTags } from 'react-tag-input';
import venueTagsArray from "./tags";
import './VenueTags.css';

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

class VenueTags extends React.Component {
  handleDelete(i) {
    this.props.onChange(this.props.tags.filter((tag, index) => index !== i));
  }

  handleAddition(tag) {
    this.props.onChange([...this.props.tags, tag.id.toLowerCase()]);
  }

  render() {
    var tags = [];
    if (this.props.tags && this.props.tags.length > 0) {
      this.props.tags.forEach(tag => {
        tags.push({ id: tag, text: tag });
      });
    }
    return (
      <ReactTags
        suggestions={venueTagsArray.map(e => {
          return { id: e, text: e };
        })}
        tags={tags}
        handleDelete={this.handleDelete.bind(this)}
        handleAddition={this.handleAddition.bind(this)}
        allowDragDrop={false}
        delimiters={delimiters}
        placeholder="Add tag"
      />
    );
  }
}

export default VenueTags;
