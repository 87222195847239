export const findVenueToken = `query FindVenueToken($search: String) {
  findVenueToken(search: $search) {
    venueToken
    venueName
    venueType
    tags
    bssTokens {
      bssToken
      affinity
      matchType
    }
  }
}
`;

export const ping = `query Ping($id: String) {
  ping(id: $id) {
    id 
  }
}
`;
