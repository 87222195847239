import React from "react";
import "./SsidInput.css";

class SsidInput extends React.Component {
  constructor(props) {
    super(props);
    const ssid = props.ssid;

    this.state = {
      hexSsid: ssid ? ssid : "",
      asciiSsid: ssid ? this.hexToAscii(ssid) : "",
      valid: ssid ? this.hexIsValid(ssid) : false
    };
    this.onChangeAscii = this.onChangeAscii.bind(this);
    this.onChangeHex = this.onChangeHex.bind(this);
  }

  hexIsValid(ssid) {
    if (ssid.length % 2 !== 0) {
      return false;
    } else if (!/^[0-9A-Fa-f]+$/.test(ssid)) {
      return false;
    }
    return true;
  }

  hexToAscii(hex) {
    var readableSsid = null;
    try {
      var buffer = Buffer.from(hex, "hex");
      readableSsid = buffer.toString("utf8");
    } catch (err) {
      readableSsid = "?";
    }
    return readableSsid;
  }

  asciiToHex(ascii) {
    var hex = [];
    for (var n = 0, l = ascii.length; n < l; n++) {
      hex.push(Number(ascii.charCodeAt(n)).toString(16));
    }
    return hex.join("");
  }

  onChangeAscii(newAscii) {
    const newHex = this.asciiToHex(newAscii);
    this.setState({
      asciiSsid: newAscii,
      hexSsid: newHex,
      valid: true
    });
    this.props.onChange(newHex);
  }

  onChangeHex(newHex) {
    const sanitizedNewHex = newHex
      .replace(/[^A-Fa-f0-9]/g, "")
      .toLowerCase()
      .slice(0, 64);

    if (this.hexIsValid(sanitizedNewHex)) {
      this.setState({
        hexSsid: sanitizedNewHex,
        asciiSsid: this.hexToAscii(sanitizedNewHex),
        valid: true
      });
      this.props.onChange(sanitizedNewHex);
    } else {
      this.setState({
        hexSsid: sanitizedNewHex,
        asciiSsid: this.hexToAscii(sanitizedNewHex.slice(0, -1)),
        valid: false
      });
    }
  }

  render() {
    var status;
    if (this.state.valid) {
      status = <div className="validInput" data-testid="validInput" />;
    } else {
      status = <div className="invalidInput" data-testid="invalidInput" />;
    }

    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td rowSpan="2">{status}</td>
              <td>
                <input
                  type="text"
                  data-testid="asciiInput"
                  placeholder="ASCII SSID..."
                  value={this.state.asciiSsid}
                  size={this.props.size}
                  onChange={event => {
                    return this.onChangeAscii(event.target.value);
                  }}
                />
              </td>
            </tr>
            <tr>
              <td>
                <input
                  type="text"
                  data-testid="hexInput"
                  placeholder="Hex SSID..."
                  value={this.state.hexSsid}
                  size={this.props.size}
                  onChange={event => {
                    return this.onChangeHex(event.target.value);
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default SsidInput;
