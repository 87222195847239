import React from "react";
import VenueEditor from "./VenueEditor";
import Search from "./Search";

import * as queries from "./graphql/queries";
import * as mutations from "./graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";

import { Route, withRouter } from "react-router-dom";

class VenueCurationConsole extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataDescription: "",
      status: "",
      fetching: false
    };
    this.startSearch = this.startSearch.bind(this);
    this.onSelectRow = this.onSelectRow.bind(this);
    this.onCancelEditVenueToken = this.onCancelEditVenueToken.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onDeleteRow = this.onDeleteRow.bind(this);
    this.onCreate = this.onCreate.bind(this);
  }

  async componentDidMount() {
    this.setState({
      status: "Initializing...",
      fetching: true
    });
    // Wake up the lambda function if it was sleeping.
    // TODO - how to catch errors cleanly?
    await API.graphql(graphqlOperation(queries.ping, { id: null }));
    this.setState({
      status: "",
      fetching: false
    });
  }

  onCancelEditVenueToken(venueToken) {
    this.setState({ editing: null });
  }

  async saveVenueToken(venueTokenItem) {
    // TODO - how to catch errors cleanly?
    await API.graphql(
      graphqlOperation(mutations.putVenueToken, venueTokenItem)
    );
  }

  async deleteVenueToken(venueToken) {
    // TODO - how to catch errors cleanly?
    await API.graphql(
      graphqlOperation(mutations.deleteVenueToken, { venueToken })
    );
  }

  async deleteBssTokens(bssTokens) {
    // TODO - how to catch errors cleanly?
    await API.graphql(
      graphqlOperation(mutations.deleteBssTokens, { bssTokens: bssTokens })
    );
  }

  onDeleteRow(venueToken) {
    var index = this.state.data.findIndex(e => {
      return e.venueToken === venueToken;
    });

    this.setState({
      editing: null,
      status: "Deleting...",
      fetching: true,
      dataDescription: "",
      data: [].concat(
        this.state.data.slice(0, index),
        this.state.data.slice(index + 1)
      )
    });
    this.deleteVenueToken(venueToken);
    this.setState({
      status: "",
      fetching: false
    });
  }

  onSave(params) {
    this.props.history.goBack();
    this.setState({
      status: "Saving changes...",
      fetching: true
    });
    var {
      newVenueTokenItem,
      bssTokensToDelete,
      bssTokensToWrite,
      unchangedBssTokens
    } = params;

    this.saveVenueToken(
      Object.assign(newVenueTokenItem, { bssTokens: bssTokensToWrite })
    );
    if (bssTokensToDelete.length > 0) {
      this.deleteBssTokens(
        bssTokensToDelete.map(e => {
          return { bssToken: e, venueToken: newVenueTokenItem.venueToken };
        })
      );
    }
    var index = this.state.data.findIndex(e => {
      return e.venueToken === newVenueTokenItem.venueToken;
    });

    var data;
    if (index === -1) {
      data = [
        Object.assign(newVenueTokenItem, {
          bssTokens: [...bssTokensToWrite, ...unchangedBssTokens]
        }),
        ...this.state.data
      ];
    } else {
      data = [].concat(
        this.state.data.slice(0, index),
        Object.assign(newVenueTokenItem, {
          bssTokens: [...bssTokensToWrite, ...unchangedBssTokens]
        }),
        this.state.data.slice(index + 1)
      );
    }
    this.setState({
      editing: null,
      status: "",
      dataDescription: "",
      data,
      fetching: false
    });
  }

  async venueTokenExists(venueToken, callback) {
    // TODO - how to catch errors cleanly?
    var res = await API.graphql(
      graphqlOperation(queries.findVenueToken, { search: venueToken })
    );
    if (res.data.findVenueToken.length === 0) {
      return callback(null, false);
    } else {
      return callback(
        null,
        res.data.findVenueToken.findIndex(e => {
          return e.venueToken === venueToken;
        }) > -1
      );
    }
  }

  async startSearch(search) {
    this.setState({
      data: [],
      dataDescription: "",
      status: "Fetching results for search term: '" + search + "'",
      fetching: true
    });
    // TODO - how to catch errors cleanly?
    var res = await API.graphql(
      graphqlOperation(queries.findVenueToken, { search })
    );
    this.setState({ data: res.data.findVenueToken, fetching: false });
    if (res.data.findVenueToken.length === 0) {
      this.setState({
        dataDescription: "Not hits for search term: '" + search + "'"
      });
    } else {
      this.setState({
        dataDescription:
          res.data.findVenueToken.length +
          " results for search term: '" +
          search +
          "'"
      });
    }
    this.setState({
      status: ""
    });
  }

  onSelectRow(venueToken) {
    this.setState({ editing: venueToken });
    this.props.history.push("/edit/" + encodeURI(venueToken));
  }

  onCreate() {
    this.setState({ editing: "" });
    this.props.history.push("/create");
  }

  render() {
    return (
      <div>
        <Route
          path="/"
          exact
          render={props => (
            <Search
              {...props}
              startSearch={this.startSearch}
              status={this.state.status}
              onCreate={this.onCreate}
              fetching={this.state.fetching}
              data={this.state.data}
              dataDescription={this.state.dataDescription}
              onSelectRow={this.onSelectRow}
              onDeleteRow={this.onDeleteRow}
            />
          )}
        />
        <Route
          path="/edit/:venueToken"
          exact
          render={params => {
            var venueToken = params.match.params.venueToken;
            var selectedVenueToken = this.state.data.find(e => {
              return e.venueToken === venueToken;
            });
            return (
              <VenueEditor
                venueTokenObject={selectedVenueToken}
                onSave={this.onSave}
                onCancel={this.onCancelEditVenueToken}
              />
            );
          }}
        />
        <Route
          path="/create"
          exact
          render={() => {
            return (
              <VenueEditor
                venueTokenObject={{ tags: [], bssTokens: [] }}
                onSave={this.onSave}
                onCancel={this.onCancelEditVenueToken}
                venueTokenExists={this.venueTokenExists}
                create={true}
              />
            );
          }}
        />
      </div>
    );
  }
}

export default withRouter(VenueCurationConsole);
