import React from "react";

class VenueTokenTable extends React.Component {
  render() {
    const rows = [];

    this.props.data.forEach(e => {
      const bssTokens = [];

      if (e.bssTokens && e.bssTokens.length > 0) {
        e.bssTokens.forEach(bssToken => {
          var [type, token] = bssToken.bssToken.split(":");
          if (type === "s") {
            var readableSsid = null;
            try {
              var buffer = Buffer.from(token, "hex");
              readableSsid = buffer.toString("utf8");
            } catch (err) {
              readableSsid = "?";
            }

            bssTokens.push(
              <tr id="bssToken" key={bssToken.bssToken}>
                <td>ssid</td>
                <td>
                  <div>{token}</div>
                  <div>{"<hex(" + readableSsid + ")>"}</div>
                </td>
              </tr>
            );
          } else if (type === "w") {
            bssTokens.push(
              <tr id="bssToken" key={bssToken.bssToken}>
                <td>wisprLocationName</td>
                <td>{token}</td>
              </tr>
            );
          } else if (type === "l") {
            bssTokens.push(
              <tr id="bssToken" key={bssToken.bssToken}>
                <td>locationToken</td>
                <td>{token}</td>
              </tr>
            );
          } else if (type === "n") {
            bssTokens.push(
              <tr id="bssToken" key={bssToken.bssToken}>
                <td>networkId</td>
                <td>{token}</td>
              </tr>
            );
          }
        });
      }

      var readableVenueToken = null;
      try {
        if (e.venueToken.startsWith("poi_")) {
          readableVenueToken =
            "poi_<hex(" +
            Buffer.from(e.venueToken.slice(4), "hex").toString("utf8") +
            ")>";
        } else if (e.venueToken.endsWith('%')) {
          readableVenueToken =
            "<hex(" + Buffer.from(e.venueToken.slice(0, -1), "hex").toString("utf8") + ")*>";
        } else {
          readableVenueToken =
            "<hex(" + Buffer.from(e.venueToken, "hex").toString("utf8") + ")>";
        }
      } catch (err) {
        readableVenueToken = null;
      }
      rows.push(
        <tr id="venueToken" key={e.venueToken}>
          <td>
            <input
              type="button"
              onClick={() => {
                this.props.onSelectRow(e.venueToken);
              }}
              value="Edit"
            />
          </td>
          <td>
            <input
              type="button"
              onClick={() => {
                this.props.onDeleteRow(e.venueToken);
              }}
              value="Delete"
            />
          </td>
          <td>
            <div>{e.venueToken}</div>
            <div>{readableVenueToken}</div>
          </td>
          <td>{e.venueName}</td>
          <td>{e.venueType}</td>
          <td>{e.tags == null ? "" : e.tags.join(", ")}</td>
          <td>
            <table>
              <tbody>{bssTokens}</tbody>
            </table>
          </td>
        </tr>
      );
    });

    return (
      <div>
        <input type="button" value="Create" onClick={this.props.onCreate} />

        <div>{this.props.description}</div>
        {rows.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th />
                <th />
                <th>Venue Token</th>
                <th>Venue Name</th>
                <th>Type</th>
                <th>Tags</th>
                <th>BSS Tokens</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default VenueTokenTable;
