module.exports = [
    "airport",
    "american restaurant",
    "aquarium",
    "argentinian",
    "arts",
    "automotive",
    "automotive shop",
    "automotive store",
    "bank",
    "bar",
    "beauty",
    "beauty salon",
    "bike shop",
    "bluedonkey",
    "book store",
    "bowling",
    "bus",
    "business",
    "bus station",
    "cafe",
    "car dealer",
    "caribbean",
    "carvery",
    "casino",
    "children's activity",
    "clothing",
    "club",
    "coffee",
    "coffeeshop",
    "college",
    "commercial wifi",
    "construction",
    "convenience",
    "convenience store",
    "convention center",
    "dentist",
    "department store",
    "devicescapetest",
    "discount store",
    "diy",
    "education",
    "electronics",
    "entertainment",
    "family",
    "family restaurant",
    "fashion",
    "financial",
    "french",
    "french restaurant",
    "furniture",
    "gambling",
    "gas station",
    "golf",
    "government",
    "greek",
    "grocery",
    "grocery store",
    "gym",
    "healthcare",
    "home",
    "home improvement",
    "hospital",
    "hotel",
    "hotel",
    "hotel",
    "hubone",
    "italian",
    "italian restaurant",
    "italian resturant",
    "k12 school",
    "kitchenware",
    "laundry",
    "leisure",
    "library",
    "local services",
    "mall",
    "marina",
    "mediterranean",
    "mexican",
    "military",
    "mobile",
    "movie theater",
    "municipal",
    "museum",
    "office",
    "outdoor space",
    "outdoor supply store",
    "pet",
    "pharmacy",
    "phone hotspot",
    "pizza",
    "place of worship",
    "prereleasetest",
    "pretzels",
    "pub",
    "qsr",
    "religious",
    "residence",
    "restaurant",
    "retail",
    "school (k-12)",
    "shipping",
    "shoes",
    "shopping mall",
    "shop & service",
    "sporting goods",
    "sports stadium",
    "stadium",
    "steak",
    "store",
    "supermarket",
    "superstore",
    "swimming pool",
    "texmex",
    "thai",
    "theme park",
    "toy store",
    "transportation",
    "travel",
    "university",
    "water park",
    "zoo",
];

